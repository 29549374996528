import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import Loader from "../controls/loader/Loader";
import { LoaderMessages } from "../../utility/Messages";

export const SecureRoute: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState?.isAuthenticated, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return <Loader msg={LoaderMessages.signIn} />;
  }

  return <Outlet />;
};
