import { KpiDashboardVisual } from "../../../model/userReportsModel";
import DossierContainer from "../../dossier/DossierContainer";
import "./KpiDashboardContainer.scss";

interface KpiDashboardContainerProps {
  dashboards: KpiDashboardVisual[];
}

function KpiDashboardContainer(props: KpiDashboardContainerProps) {
  const dashboards = props.dashboards.map((dashboard) => (
    <div key={dashboard.groupName} className="kpiDashboardDossierContainer">
      <div className="kpiDashboardDossierModule">{dashboard.groupName}</div>
      <div className="kpiDashboardDossier">
        <DossierContainer
          dossierId={dashboard.dashboardId}
          containerId={`mstr-container-${dashboard.dashboardId}`}
        ></DossierContainer>
      </div>
    </div>
  ));

  return <>{dashboards}</>;
}

export default KpiDashboardContainer;
