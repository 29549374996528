const ACCOUNTING_DASHBOARD_INSATNACE_ID = "accounting_dashboard_instance_id";
const ACCOUNTING_REPORT_INSATNACE_ID = "accounting_report_instance_id";

export const getAccountingDashboardInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_DASHBOARD_INSATNACE_ID);

export const getAccountingReportInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_REPORT_INSATNACE_ID);

export const setAccountingDashboardInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_DASHBOARD_INSATNACE_ID, id);

export const setAccountingReportInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_REPORT_INSATNACE_ID, id);

export const clearAccountingInstanceIds = () => {
  sessionStorage.removeItem(ACCOUNTING_DASHBOARD_INSATNACE_ID);
  sessionStorage.removeItem(ACCOUNTING_REPORT_INSATNACE_ID);
};
