import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import "./Loader.scss";
import Modal from "../modal/Modal";

function Loader(props: { size?: "default" | "small"; msg?: string }) {
  return (
    <Modal open>
      <div className="tokenLoader">
        <LoadingIndicator
          htmlId="tokenLoadingIndicator"
          size={props.size}
          color="#2372b8"
        />
        <div className="loaderMsg">{props.msg}</div>
      </div>
    </Modal>
  );
}
export default Loader;
