import { useEffect, useState } from "react";
import "./BridgeBar.scss";
import { currentEnvironment } from "../../../environments/environments";
import { useOktaAuth } from "@okta/okta-react";
import { Bridge_Bar_URL } from "../../../config/bridgeBarConfig";
import { initializeBridgeBar } from "../../../services/bridgeBarService";

function BridgeBar() {
  const [loaded, setLoaded] = useState(false);
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (loaded) return;
    var s = document.createElement("script");
    s.src = Bridge_Bar_URL;
    document.head.appendChild(s);
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      const environment = currentEnvironment();
      const jti = authState?.accessToken?.claims?.jti;
      initializeBridgeBar(jti, environment);
    }
  }, [authState?.accessToken?.claims?.jti, loaded]);

  return <span id="bridge-bar" className="bridge"></span>;
}
export default BridgeBar;
