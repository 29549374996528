import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getUserReportByReportIdFromSession } from "../../services/userSettingsService";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import ReportContainer from "../visuals/reportContainer/ReportContainer";
import { getLaunchDarklyFlagKeyFromReportId } from "../../utility/launchDarklyFlagHelper";
import { ErrorMessages } from "../../utility/Messages";
import NoAccess from "../controls/noAccess/NoAccess";
import { AdminUserContext } from "../../contexts";
import getError from "../../utility/errorHelper";

function ReportPage() {
  const reportId = useCustomSearchParams("id");
  const report = reportId
    ? getUserReportByReportIdFromSession(reportId)
    : undefined;
  const visualId = report?.visualKey;
  const pageKey = report?.pageKey;
  const mstrLink = useCustomSearchParams("mstrLink") ?? undefined;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();
  const isAdminUser = useContext(AdminUserContext);

  useEffect(() => {
    if (!reportId) {
      navigate("/error", {
        state: {
          error: getError(ErrorMessages.incorrectResource),
        },
      });
    }
  }, [navigate, reportId]);

  if (!reportId)
    return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;

  const hasReportAccess =
    getLaunchDarklyFlagKeyFromReportId(reportId) &&
    launchDarklyFlags[getLaunchDarklyFlagKeyFromReportId(reportId)!];

  if (isAdminUser || hasReportAccess)
    return (
      <ReportContainer
        dossierId={reportId}
        visualId={visualId}
        pageKey={pageKey}
        mstrLink={mstrLink}
      />
    );

  return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;
}

export default ReportPage;
