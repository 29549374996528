import "./Modal.scss";

interface ModalProps {
  open: boolean;
  onClose?: () => {};
  children: React.ReactNode;
}

function Modal(props: ModalProps) {
  const handleClose = () => {
    props.onClose?.();
  };

  if (!props.open) return null;

  return (
    <div onClick={handleClose} className="overlay">
      <div className="modal">{props.children}</div>
    </div>
  );
}

export default Modal;
