import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { interstateThemeRegistry } from "@interstate/components/InterstateTheming/themes/interstateThemeRegistry";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <InterstateThemeProvider
      themeName="Interstate"
      applicationName="DMS+ One View"
      scopeName="DMS+ One View"
      themeRegistries={[interstateThemeRegistry]}
    >
      <Router>
        <App />
      </Router>
    </InterstateThemeProvider>
  </React.StrictMode>
);
