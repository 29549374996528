import BridgeBarContainer from "../bridgeBarContainer/BridgeBarContainer";
import FooterContainer from "../footerContainer/FooterContainer";
import NavBarContainer from "../navBarContainer/NavBarContainer";
import VisualContainer from "../visualContainer/VisualContainer";
import "./OneViewContainer.scss";

function OneViewContainer(props: any) {
  return (
    <div className="oneViewContainer">
      <BridgeBarContainer />
      <NavBarContainer />
      <VisualContainer>{props.children}</VisualContainer>
      <FooterContainer></FooterContainer>
    </div>
  );
}
export default OneViewContainer;
