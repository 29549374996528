export interface ErrorModel {
  message: string;
  prevPath: string;
  isCustom?: boolean;
}

function getError(message: string) {
  const error: ErrorModel = {
    message: message,
    prevPath: window.location.href,
    isCustom: true,
  };
  return error;
}

export default getError;
