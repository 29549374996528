export enum Deployment {
  LOCAL = "local",
  DEV = "dev",
  QA = "qa",
  PROD = "prod",
}
const deployments: Record<string, Deployment> = {
  localhost: Deployment.LOCAL,
  "oneview-dev.reports-test.coxautoinc.com": Deployment.DEV,
  "oneview-qa.reports-test.coxautoinc.com": Deployment.QA,
  "oneview.reports.coxautoinc.com": Deployment.PROD,
};

export function currentDeployment(location: Location): Deployment {
  return deployments[location.hostname];
}
