import Footer from "../../controls/footer/Footer";
import './FooterContainer.scss'

function FooterContainer() {
  return (
    <div className="footerContainer">
      <Footer></Footer>
    </div>
  );
}

export default FooterContainer;
