import "./NavBarContainer.scss";
import NavBar from "../../controls/navBar/NavBar";

function NavBarContainer() {
  return (
    <div className="navBarContainer">
      <NavBar></NavBar>
    </div>
  );
}

export default NavBarContainer;
