import { getUserReportByReportIdFromSession } from "../services/userSettingsService";

export const landingPageFlag = "dms-plus-oneview-landing-page";
export const pendoFeatureFlag = "dms-plus-oneview-pendo";
export const kpiDashboardFlag = "dms-plus-oneview-kpi-dashboard";

export const getLaunchDarklyFlagKeyFromReportName = (name: string) =>
  `dms-plus-oneview-${name.replaceAll(" ", "-").toLowerCase()}-report`;

export const getLaunchDarklyFlagKeyFromReportId = (id: string) => {
  const report = getUserReportByReportIdFromSession(id);
  return report
    ? getLaunchDarklyFlagKeyFromReportName(report.reportName)
    : undefined;
};
