import "./ErrorContainer.scss";
import BridgeBarContainer from "../bridgeBarContainer/BridgeBarContainer";
import Error from "../../controls/error/Error";
import FooterContainer from "../footerContainer/FooterContainer";
import { NavLink, useLocation } from "react-router-dom";
import { ErrorModel } from "../../../utility/errorHelper";

function ErrorContainer() {
  const location = useLocation();
  const error: ErrorModel | undefined = location.state?.error as ErrorModel;
  const isPreviousPathExist =
    error.prevPath && error.prevPath !== window.location.href;
  let navigatePath = "/";
  if (isPreviousPathExist) {
    navigatePath = error.prevPath.replace("mstr_invoked=true&", "");
    navigatePath = navigatePath.replace("&mstr_invoked=true", "");
    navigatePath = navigatePath.replace("?mstr_invoked=true", "");
  }
  return (
    <div className="errorContainer">
      <BridgeBarContainer></BridgeBarContainer>
      <div className="errorLink">
        <Error error={error} />
        <NavLink
          to={navigatePath}
          className={"prevLink"}
          title="By clicking this link you will be asked to log in again. The session will take you to the page you were on before seeing this message."
        >
          Click here to renew your session.
        </NavLink>
      </div>
      <FooterContainer></FooterContainer>
    </div>
  );
}
export default ErrorContainer;
