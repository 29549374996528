import { useContext } from "react";
import Dossier from "./Dossier";
import { TokenContext } from "../../contexts";

interface DossierContainerProps {
  dossierId: string;
  style?: React.CSSProperties;
  containerId?: string;
  pageKey?: string;
  visualId?: string;
  size?: "maximized" | "normal";
  filterUpdate?: () => void;
  resizeButtonVisible?: boolean;
  onGraphicSelected?: () => void;
  mstrLink?: string;
}

function DossierContainer(props: DossierContainerProps) {
  const token = useContext(TokenContext);
  return (
    <Dossier
      dossierId={props.dossierId}
      authToken={token}
      style={props.style}
      containerId={props.containerId}
      pageKey={props.pageKey}
      visualId={props.visualId}
      size={props.size}
      resizeButtonVisible={props.resizeButtonVisible}
      onGraphicSelected={props.onGraphicSelected}
      mstrLink={props.mstrLink}
    />
  );
}

export default DossierContainer;
