import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { currentEnvironment } from "../../environments/environments";
import {
  MSTR_ACCOUNTING_DASHBOARD_ID,
  MSTR_ACCOUNTING_REPORT_ID,
  MSTR_PROJECT_ID,
} from "../../config/mstrConfig";
import { ErrorMessages } from "../../utility/Messages";
import { getPendoModelFromSession } from "../../services/pendoService";
import { useFlags } from "launchdarkly-react-client-sdk";
import { pendoFeatureFlag } from "../../utility/launchDarklyFlagHelper";
import { createInstance } from "../../services/mstrRestSevice";
import {
  getAccountingDashboardInstanceId,
  getAccountingReportInstanceId,
  setAccountingDashboardInstanceId,
  setAccountingReportInstanceId,
} from "../../utility/sessionStorageHelper";
import getError from "../../utility/errorHelper";

const Dossier = ({
  dossierId,
  authToken,
  style,
  containerId,
  pageKey,
  visualId,
  size,
  resizeButtonVisible,
  onGraphicSelected,
  mstrLink,
}) => {
  const [error, setError] = useState(undefined);
  const id = containerId ?? "mstr-container";
  const isVisualMaximizedDashboard = size === "maximized";
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();
  const isAccountingReport = dossierId === MSTR_ACCOUNTING_REPORT_ID;
  const isAccountingDashboard = dossierId === MSTR_ACCOUNTING_DASHBOARD_ID;
  const isAccounting = isAccountingReport || isAccountingDashboard;
  const isBookmark = mstrLink?.includes("bookmarks");

  const addPendoToDossier = useCallback(() => {
    setTimeout(() => {
      const frame = document.querySelector("iframe");
      const pendoModel = getPendoModelFromSession();
      if (pendoModel && launchDarklyFlags[pendoFeatureFlag]) {
        frame.contentWindow.postMessage({ visitor: pendoModel.visitor }, "*");
      }
    }, 5000);
  }, [launchDarklyFlags]);

  const errorHandler = useCallback((error) => {
    console.log(`Error in MicroStrategy report loading - ${error.message}`);
    setError(getError(ErrorMessages.mstrReportLoadError));
  }, []);

  const sessionErrorHandler = useCallback((error) => {
    console.log(`Session error- ${error.message}`);
    setError(getError(ErrorMessages.sessionError));
  }, []);

  useEffect(() => setError(undefined), []);

  useEffect(() => {
    if (error) {
      navigate("/error", {
        state: {
          error: error,
        },
      });
    }
  }, [error, navigate]);

  useEffect(() => {
    const environment = currentEnvironment();
    const dossierPlcHolder = document.getElementById(id);
    const dossierProps = {
      placeholder: dossierPlcHolder,
      url: mstrLink
        ? `${environment.customConfiguration.mstrUrl}/${mstrLink}`
        : `${
            environment.customConfiguration.mstrUrl
          }/app/${MSTR_PROJECT_ID}/${dossierId}/${pageKey ?? ""}`,
      shareFeature: {
        enabled: true,
        invite: false,
        link: false,
        email: false,
        export: true,
        download: false,
        shareDossier: true,
        subscribe: true,
      },
      enableCustomAuthentication: true,
      enableResponsive: true,
      showErrorPopup: false,
      enableCollaboration: true,
      disableCustomErrorHandlerOnCreate: false,
      disableErrorPopupWindow: true,
      dossierFeature: {
        readonly: isVisualMaximizedDashboard,
      },
      navigationBar: {
        enabled: !isVisualMaximizedDashboard,
        gotoLibrary: false,
        title: !isVisualMaximizedDashboard,
        toc: !isVisualMaximizedDashboard,
        reset: !isVisualMaximizedDashboard,
        reprompt: !isVisualMaximizedDashboard,
        share: !isVisualMaximizedDashboard,
        comment: false,
        notification: !isVisualMaximizedDashboard,
        filter: true,
        options: false,
        search: false,
        bookmark: !isVisualMaximizedDashboard,
        undoRedo: !isVisualMaximizedDashboard,
      },
      filterFeature: {
        enabled: true,
        edit: true,
        summary: true,
      },
      persistViewState: true,
      visualizationAppearances: isVisualMaximizedDashboard
        ? [
            {
              visualizationKey: visualId,
              size: "maximized",
              resizeButtonVisible: false,
            },
          ]
        : undefined,
      errorHandler: errorHandler,
      sessionErrorHandler: sessionErrorHandler,
      customAuthenticationType:
        window.microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN,
      getLoginToken: () =>
        new Promise((resolve, reject) => {
          if (authToken) {
            resolve(authToken);
          } else {
            reject({
              status: 501,
              statusText: "Error executing custom task...",
            });
          }
        }),
    };

    if (isAccounting && !isBookmark) {
      createInstanceByApiForAccounting();
    } else {
      createDossier();
    }

    return function cleanup() {
      window.microstrategy.dossier.destroy({
        placeholder: dossierPlcHolder,
      });
    };

    function createInstanceByApiForAccounting() {
      const sourceInstanceId = isAccountingReport
        ? getAccountingDashboardInstanceId()
        : getAccountingReportInstanceId();

      if (sourceInstanceId) {
        const payload = {
          filters: null,
          persistViewState: true,
          resolveOnly: false,
          linkingInfo: {
            sourceInstanceId: sourceInstanceId,
            sourceProjectId: MSTR_PROJECT_ID,
            selectorMode: 2,
            passFilter: 1,
          },
          bookmarkInfo: {
            resetManipulation: true,
          },
          disableManipulationsAutoSaving: true,
        };

        if (isAccountingReport) {
          payload.linkingInfo.passInCanvasSelection = true;
          payload.linkingInfo.targetPageKey = pageKey;
          payload.linkingInfo.vizSelection = `{"sels":[],"isBranch":false}`;
        }

        createInstance(dossierId, payload, authToken)
          .then((instanceId) => {
            if (instanceId) {
              dossierProps.instance = {
                mid: instanceId,
                status: 1,
              };
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => createDossier());
      } else {
        createDossier();
      }
    }

    function createDossier() {
      window.microstrategy.dossier
        .create(dossierProps)
        .then((dossierInstance) => {
          customizeDossier();
          addPendoToDossier();

          function customizeDossier() {
            updateEventHandlers();
            storeAccountingInstanceIds();

            function updateEventHandlers() {
              dossierInstance.addCustomErrorHandler(errorHandler, false);
              dossierInstance.removeSessionErrorhandler();
              dossierInstance.addSessionErrorHandler(errorHandler);
              dossierInstance.registerEventHandler(
                window.microstrategy.dossier.EventType.ON_GRAPHICS_SELECTED,
                onGraphicSelected
              );
              dossierInstance.registerEventHandler(
                window.microstrategy.dossier.EventType.ON_PAGE_RENDER_FINISHED,
                updateVisualSize
              );
            }

            function updateVisualSize() {
              normalizeReportVisualSize();
              maximizeAccountingDashboardSize();

              function maximizeAccountingDashboardSize() {
                if (isAccountingDashboard) {
                  updateSize("maximized");
                }
              }

              function normalizeReportVisualSize() {
                if (
                  !isVisualMaximizedDashboard &&
                  visualId &&
                  dossierInstance
                ) {
                  const hasVisual = dossierInstance
                    .getCurrentPageVisualizationList()
                    .then((visuals) => visuals.some((v) => v.key === visualId));
                  if (hasVisual) updateSize("normal");
                }
              }

              function updateSize(size) {
                let timesRun = 0;
                const interval = setInterval(() => {
                  if (timesRun === 25) {
                    clearInterval(interval);
                  }
                  dossierInstance
                    .changeVisualizationSize({
                      visualizationKey: visualId,
                      size: size,
                      resizeButtonVisible: size === "normal",
                    })
                    .then(() => {
                      clearInterval(interval);
                    })
                    .catch((e) => console.log(e));
                  timesRun++;
                }, 200);
              }
            }

            function storeAccountingInstanceIds() {
              if (isAccounting && !isBookmark) {
                dossierInstance.getDossierInstanceId().then((id) => {
                  isAccountingReport
                    ? setAccountingReportInstanceId(id)
                    : setAccountingDashboardInstanceId(id);
                });
              }
            }
          }
        })
        .catch((e) => console.log(e));
    }
  }, [
    dossierId,
    authToken,
    id,
    visualId,
    isVisualMaximizedDashboard,
    resizeButtonVisible,
    onGraphicSelected,
    pageKey,
    errorHandler,
    addPendoToDossier,
    sessionErrorHandler,
    isAccountingDashboard,
    mstrLink,
    isBookmark,
    isAccountingReport,
    isAccounting,
  ]);

  if (error) return <></>;

  return (
    <>
      <div
        id={id}
        style={{
          display: authToken ? "flex" : "none",
          flexGrow: 1,
          height: "100%",
          ...style,
        }}
        sandbox="allow-same-origin allow-scripts"
      />
    </>
  );
};

export default Dossier;
