import { currentDeployment, Deployment } from "./deployment";


export interface Runtime {
  deployment: Deployment;
}

export function currentRuntime(): Runtime {
  return {
    deployment: currentDeployment(window.location),
  };
}
