import { currentEnvironment } from "../environments/environments";
import { UserAccount } from "../model/userAccountModel";
import { UserReport } from "../model/userReportsModel";
import { UserSettingsApiService } from "./apiService";

export function InitUserSettings() {
  const environment = currentEnvironment();
  UserSettingsApiService.get(
    `${environment.customConfiguration.userSettingUrl}/init`
  );
}

export async function getUserReports(
  token: string
): Promise<UserReport[] | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/reports`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      console.log("api call to setting api has failed.");
      return;
    }

    const userReports = (await response.json()) as UserReport[];
    sessionStorage.setItem("user_reports", JSON.stringify(userReports));

    return userReports;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function getUserReportsFromSession(): UserReport[] {
  const userReportsFromSessionStorage = sessionStorage.getItem("user_reports");
  if (userReportsFromSessionStorage) {
    return JSON.parse(userReportsFromSessionStorage) as UserReport[];
  }
  return [];
}

export function clearUserReportsFromSession() {
  sessionStorage.removeItem("user_reports");
}

export function getUserReportByReportIdFromSession(
  reportId: string
): UserReport | undefined {
  const userReportsFromSessionStorage = sessionStorage.getItem("user_reports");
  if (userReportsFromSessionStorage) {
    const reports = JSON.parse(userReportsFromSessionStorage) as UserReport[];
    const report = reports.find((f) => f.reportId === reportId);
    return report;
  }
}

export async function getUserAccount(
  token: string
): Promise<UserAccount | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/account`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      console.log("api call to setting api to get user account has failed.");
      return;
    }

    const userAccount = (await response.json()) as UserAccount;
    sessionStorage.setItem("user_account", JSON.stringify(userAccount));

    return userAccount;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function getUserAccountFromSession(): UserAccount | undefined {
  const userAccountsFromSessionStorage = sessionStorage.getItem("user_account");
  if (userAccountsFromSessionStorage) {
    return JSON.parse(userAccountsFromSessionStorage) as UserAccount;
  }
  return undefined;
}

export function clearUserAccountFromSession() {
  sessionStorage.removeItem("user_account");
}
