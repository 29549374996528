import { PendoModel } from "../model/pendoModel";

export function savePendoModelToSession(pendoModel: PendoModel) {
  sessionStorage.setItem("pendo_model", JSON.stringify(pendoModel));
}

export function getPendoModelFromSession(): PendoModel | undefined {
  const userReportsFromSessionStorage = sessionStorage.getItem("pendo_model");
  if (userReportsFromSessionStorage) {
    return JSON.parse(userReportsFromSessionStorage) as PendoModel;
  }
  return undefined;
}

export function clearPendoModelFromSession() {
  sessionStorage.removeItem("pendo_model");
}
