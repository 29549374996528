import { useCallback, useEffect, useState } from "react";
import Logout from "@common-core/okta-logout";
import Loader from "../loader/Loader";
import { mstrLogout } from "../../../services/mstrRestSevice";
import { clearUserReportsFromSession } from "../../../services/userSettingsService";
import { ErrorMessages, LoaderMessages } from "../../../utility/Messages";
import { clearPendoModelFromSession } from "../../../services/pendoService";
import { clearAccountingInstanceIds } from "../../../utility/sessionStorageHelper";
import { useNavigate } from "react-router-dom";
import getError from "../../../utility/errorHelper";

function AppLogout() {
  const [mstrLoggedOut, setMstrLoggedOut] = useState<"done" | "started">(
    "started"
  );
  const navigate = useNavigate();

  const navigateToError = useCallback(() => {
    navigate("/error", {
      state: {
        error: getError(ErrorMessages.mstrSessionCloseError),
      },
    });
  }, [navigate]);

  useEffect(() => {
    clearUserReportsFromSession();
    clearAccountingInstanceIds();
    clearPendoModelFromSession();
    if (mstrLoggedOut === "started") {
      mstrLogout()
        .then((response) => {
          if (response.ok) {
            setMstrLoggedOut("done");
          } else {
            console.log("Error occured in closing MSTR session.");
            response.text().then((text) => console.log(text));
            navigateToError();
          }
        })
        .catch((error) => {
          console.log(error);
          navigateToError();
        });
    }
  }, [mstrLoggedOut, navigateToError]);

  if (mstrLoggedOut === "done") return <Logout />;

  return <Loader msg={LoaderMessages.mstrSessionLogout} />;
}

export default AppLogout;
