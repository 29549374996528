import "./Error.scss";
import { bridgeMessageMapper } from "../../../utility/bridgeMessageMapper";
import { ErrorMessages } from "../../../utility/Messages";
import { ErrorModel } from "../../../utility/errorHelper";

export interface ErrorProps {
  error?: ErrorModel;
}
function Error(props: ErrorProps) {
  const { error } = { ...props };
   
  return (
    <div className="error">
      {error?.message
        ? error.isCustom
          ? error.message
          : `${bridgeMessageMapper(error.message)}`
        : ErrorMessages.unknownError}
    </div>
  );
}
export default Error;
